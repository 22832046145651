import React, { Component } from "react";
import classes from "./Service.module.css";
import ServiceMod from "../../components/Service/ServiceMod/ServiceMod";

import fifteenImg from "../../assets/images/15min_icon.jpg";
import hourImg from "../../assets/images/1hour_icon.jpg";
import pottyImg from "../../assets/images/pottybreak_icon.jpg";
import bathImg from "../../assets/images/bathIcon.jpg";

class Service extends Component {
  render() {
    return (
      <section>
        <div className={classes.ServiceContainer} id="services">
          <h2>Our Services</h2>
          <p>
            <i>*Discounted rates available for two or more dogs.</i>
          </p>
          <ServiceMod
            link="#summer"
            title="30 Minute Walk"
            serviceIcon={fifteenImg}
            text="We at Foxtrot Dog Walking are quite aware that small dogs or older dogs do not need the same amount of exercise as a larger or younger dog. Still, we know it is necessary for all dogs to stretch their legs once a day. That is why we offer this 30 minute walk for the less energetic dog. 
"
          />
          <ServiceMod
            link="#autumn"
            title="1 Hour Walk"
            serviceIcon={hourImg}
            text="When we say we are offering a one-hour walk we mean that in the loosest way possible. The time limit, of course, is not where we are loose but we will try to use that hour to meet your dog’s exercise needs. The hour is for your dog, so we want to do what is most pleasing and most productive for that dog. "
          />
          <ServiceMod
            link="#winter"
            title="Potty Break"
            serviceIcon={pottyImg}
            text="Just how it sounds, a quick potty break for the less active dog to do their business."
          />
        </div>
        <div className={classes.BathContainer}>
            <img src={bathImg} className={classes.BathIcon} alt="Bath Icon" />
            <div className={classes.BathText}>
              <p>
                Now offering in-home baths for only 40 dollars a dog!  No fancy
                grooming or haircuts but if you provide the soap and the
                bathtub/shower/hose we'll wash your pup in the comfort of your
                own home!
              </p>
            </div>
          </div>

      </section>
    );
  }
}

export default Service;
